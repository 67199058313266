import axios from "axios";

import env from "./env";
import qs from "qs";
import {
  getAccessToken,
  getRefreshToken,
  logout,
  setTokens,
  setUser,
  UserType,
} from "./AuthState";

export let AXIOS_INSTANCE = axios.create({
  responseType: "json",
  paramsSerializer: (p) => {
    return qs.stringify(p, { arrayFormat: "repeat" });
  },
});

// prepend all calls with /service
AXIOS_INSTANCE.interceptors.request.use((config) => {
  if (config.url !== undefined && !config.url.startsWith("http")) {
    config.url = `${env.API_URL}${config.url}`;
  }
  if (!config.headers) {
    config.headers = {};
  }
  config.headers["Authorization"] = "Bearer " + getAccessToken();
  if (config.url?.includes("/oauth/token")) {
    config.headers["Authorization"] =
      "Basic cHVibGljOnZzQ2FuY0RHVjRYNFNXSG5ScHhD";
  }

  return config;
});

AXIOS_INSTANCE.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      if (
        error.response.data.error_description.startsWith("Access token expired")
      ) {
        const refreshToken = getRefreshToken();
        if (refreshToken !== null) {
          const authUrl = getAuthUrl();
          return AXIOS_INSTANCE.post<{
            access_token: string;
            refresh_token: string;
          }>(
            `${authUrl}/oauth/token?grant_type=refresh_token&refresh_token=${refreshToken}`
          )
            .then((response) => {
              const accessToken = response.data.access_token;
              const refreshToken = response.data.refresh_token;
              setTokens({
                accessToken,
                refreshToken,
              });

              error.response.config.headers["Authorization"] =
                "Bearer " + accessToken;
              return AXIOS_INSTANCE(error.response.config);
            })
            .catch((error) => {
              logout();
              return Promise.reject(error);
            });
        }

        logout();
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export function getAuthUrl() {
  return env.AUTH_API_URL;
}

export function getErrorMessageFromResponse(
  response: any,
  defaultMessage: string
) {
  const responseError =
    response?.data?.message || response?.response?.data?.message;

  if (responseError !== undefined) {
    return responseError;
  }
  return defaultMessage;
}

export async function me() {
  const authUrl = getAuthUrl();
  return await AXIOS_INSTANCE.get<UserType>(`${authUrl}/me`);
}

export async function login({
  username,
  password,
}: {
  username: string;
  password: string;
}) {
  const authUrl = getAuthUrl();
  const response = await AXIOS_INSTANCE.post<{
    access_token: string;
    refresh_token: string;
  }>(
    `${authUrl}/oauth/token?grant_type=password&username=${username}&password=${password}`
  );

  setTokens({
    accessToken: response.data.access_token,
    refreshToken: response.data.refresh_token,
  });
  const userResponse = await me();
  setUser(userResponse.data);
}
