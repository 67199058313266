import styles from "./HomePage.module.scss";
import { Button, ModalHeader } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import * as React from "react";
import { useKeyPressEvent } from "react-use";

export default function HomePage() {
  const handleMouseEnter = (e: any) => {
    e.target.style.background = "grey";
  };
  const handleMouseLeave = (e: any) => {
    e.target.style.background = "black";
  };
  const navigate = useNavigate();

  const goToBarcodeScanner = () => navigate("/barcodeScanner");
  const goToDisconnect = () => navigate("/disconnect");
  const goToLogout = () => navigate("/uitloggen");

  useKeyPressEvent("F1", () => goToBarcodeScanner());
  useKeyPressEvent("F2", () => goToDisconnect());
  useKeyPressEvent("F3", () => goToLogout());

  return (
    <>
      <div className={styles.home}>
        <ModalHeader>
          <Button
            className={styles.button}
            tag={Link}
            to="/uitloggen"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <i className="fas fa-fw fa-heart-broken mt-0" />
            uitloggen
          </Button>
        </ModalHeader>
        <h2>Styling & Visual </h2>
        <h4>electronische labels</h4>
        <br />
        <br />
        <Button
          className={styles.button}
          tag={Link}
          to="/barcodeScanner"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {" "}
          <i className="fas fa-fw fa-barcode mt-0" /> scan barcodes
        </Button>
        <br />
        <br />
        <Button
          className={styles.button}
          tag={Link}
          to="/disconnect"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <i className="fas fa-fw fa-trash-alt mt-0" />
          ontkoppel optie
        </Button>
      </div>
    </>
  );
}
