import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./Auth/LoginPage";
import LogoutPage from "./Auth/LogoutPage";
import { useIsAuthenticated } from "./AuthState";
import HomePage from "./Example/HomePage";
import BarcodeScanner from "./pages/BarcodeScanner";
import DisconnectArticle from "./pages/DisconnectArticle";

export default function AppRoutes() {
  const isAuthenticated = useIsAuthenticated();
  const loginRoutes = (
    <Route path="login">
      <Route index element={<LoginPage />} />
    </Route>
  );
  return (
    <Routes>
      {isAuthenticated ? (
        <>
          <Route path="/">
            <Route index element={<HomePage />} />
            <Route path="barcodeScanner" element={<BarcodeScanner />} />
            <Route path="disconnect" element={<DisconnectArticle />} />
            {/*<Route path="example" element={<ExamplePage />} />*/}
            {/*<Route path="example" element={<ManagerScreen />}>*/}
            {/*  <Route path=":displaySwitch" element={<ManagerScreen />} />*/}
            {/*  <Route index element={<ManagerScreen />} />*/}
            {/*</Route>*/}
          </Route>
          {loginRoutes}
          <Route path="uitloggen" element={<LogoutPage />} />
        </>
      ) : (
        <>
          {loginRoutes}
          <Route path="*" element={<Navigate to={"/login"} />} />
        </>
      )}
    </Routes>
  );
}
