import * as React from "react";
import styles from "./DisconnectArticle.module.scss";
import { Button, ModalHeader } from "reactstrap";
import FormWithDisable from "../global/FormWithDisable";
import { usePostDisconnectData } from "../api/pos/endpoints/openAPIDefinition";
import { Link, useNavigate } from "react-router-dom";
import { getErrorMessageFromResponse } from "../api";
import Error from "../global/Error";
import { useKeyPressEvent } from "react-use";

export default function DisconnectArticle() {
  const [optionId, setOptionId] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const { isLoading, isError, mutate } = usePostDisconnectData();
  const navigate = useNavigate();

  const handleMouseEnter = (e: any) => {
    e.target.style.background = "grey";
  };
  const handleMouseLeave = (e: any) => {
    e.target.style.background = "black";
  };

  const goToHomepage = () => navigate("/");
  const goToBarcodeScanner = () => navigate("/barcodeScanner");
  function refreshPage() {
    window.location.reload();
  }

  useKeyPressEvent("F3", () => refreshPage());
  useKeyPressEvent("F1", () => goToHomepage());
  useKeyPressEvent("F2", () => goToBarcodeScanner());

  return (
    <div className={styles.disconnect}>
      <ModalHeader>
        <Button
          className={styles.button}
          tag={Link}
          to="/"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <i className="fas fa-fw fa-heart mt-0" />
          home
        </Button>
        <i> </i>
        <Button
          className={styles.button}
          tag={Link}
          to="/barcodeScanner"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <i className="fas fa-fw fa-barcode mt-0" /> scan barcodes
        </Button>
      </ModalHeader>

      <h2>Styling & Visual</h2>
      <h4>electronische labels</h4>
      <FormWithDisable
        submitting={isLoading}
        className="d-flex flex-column"
        onSubmit={() => {
          setOptionId(optionId);

          mutate(
            {
              data: {
                optionId: optionId,
              },
            },
            {
              onError: (e: any) => {
                const errorMessage = getErrorMessageFromResponse(
                  e.response,
                  "Omoda nummer scannen mislukt"
                );
                setErrorMessage(errorMessage);
              },
              onSuccess: () => {
                setOptionId("");
              },
            }
          );
        }}
      >
        <br />
        <h5 className={"ms-5 d-flex mt-2"}>
          <i className="fas fa-fw fa-trash-alt mt-0" />
          ontkoppel optie
        </h5>
        <br />
        <input
          className={"ms-5 me-auto"}
          style={{ width: 208 }}
          name="optionId"
          placeholder={"omoda nummer"}
          autoFocus={true}
          value={optionId}
          onChange={(e: any) => {
            setOptionId(e.target.value);
          }}
        />
        <Button className={styles.invisible} />
        <Button
          className="btn-light ms-5 me-auto text-white"
          onClick={(e: any) => {
            refreshPage();
          }}
        >
          refresh
        </Button>
        {isError && <Error message={`${errorMessage}`} />}
      </FormWithDisable>
    </div>
  );
}
