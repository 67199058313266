// AsyncBoundary will be showed when some of the react children are loading or have errors
import * as React from "react";
import { QueryErrorResetBoundary } from "react-query";
import { ErrorBoundary } from "react-error-boundary";
import CenteredSpinner from "./global/CenteredSpinner";
import { Alert, Button } from "reactstrap";

export default function AsyncBoundary({ children }: { children: any }) {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }) => (
            <Alert color="error">
              Er is een onbekende fout opgetreden.
              <Button onClick={() => resetErrorBoundary()} variant="outlined">
                Probeer opnieuw
              </Button>
            </Alert>
          )}
          onReset={reset}
        >
          <React.Suspense fallback={<CenteredSpinner />}>
            {children}
          </React.Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
