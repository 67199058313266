import AbsoluteLabel from "./AbsoluteLabel";
import { InputProps } from "reactstrap";
import InputTyped from "./InputTyped";

interface InputLabelProps extends Omit<InputProps, "type"> {
  label: string;
  type: string;
  labelBackground?: "grey" | "white";
}

export default function InputWithLabel({
  label,
  labelBackground,
  type,
  ...rest
}: InputLabelProps) {
  const id = `input-${label.toLowerCase()}`;
  return (
    <div className="position-relative">
      <AbsoluteLabel
        for={id}
        style={labelBackground === "grey" ? { backgroundColor: "#F7F7F7" } : {}}
      >
        {label}
      </AbsoluteLabel>
      <InputTyped id={id} type={type} {...rest} />
    </div>
  );
}
