import React from "react";
import AsyncBoundary from "./AsyncBoundary";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import AppRoutes from "./AppRoutes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

export default function App() {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <AsyncBoundary>
          <AsyncBoundary>
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </AsyncBoundary>
        </AsyncBoundary>
      </QueryClientProvider>
    </React.StrictMode>
  );
}
