import * as React from "react";
import { logout } from "../AuthState";
import CenteredSpinner from "../global/CenteredSpinner";

function LogoutPage() {
  React.useEffect(() => {
    logout();
  });

  return <CenteredSpinner />;
}

export default LogoutPage;
