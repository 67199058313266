import * as React from "react";
import styles from "./BarcodeScanner.module.scss";
import { Button, ModalHeader } from "reactstrap";
import FormWithDisable from "../global/FormWithDisable";
import { usePostImportScannerData } from "../api/pos/endpoints/openAPIDefinition";
import { Link, useNavigate } from "react-router-dom";
import Error from "../global/Error";
import { getErrorMessageFromResponse } from "../api";
import { useKeyPressEvent } from "react-use";

export default function BarcodeScanner() {
  const [barcode1, setESLBarcode1] = React.useState("");
  const [barcode2, setOptionBarcode2] = React.useState("");
  const [tempBarcode, setTempBarcode] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const { isLoading, isError, mutate } = usePostImportScannerData({
    mutation: { useErrorBoundary: false },
  });
  const navigate = useNavigate();

  const handleMouseEnter = (e: any) => {
    e.target.style.background = "grey";
  };
  const handleMouseLeave = (e: any) => {
    e.target.style.background = "black";
  };

  const goToHomepage = () => navigate("/");
  const goToDisconnect = () => navigate("/disconnect");
  function refreshPage() {
    window.location.reload();
  }

  useKeyPressEvent("F1", () => goToHomepage());
  useKeyPressEvent("F2", () => goToDisconnect());
  useKeyPressEvent("F3", () => refreshPage());

  // @ts-ignore
  return (
    <div className={styles.scanner}>
      <ModalHeader>
        <Button
          className={styles.button}
          tag={Link}
          to="/"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <i className="fas fa-fw fa-heart mt-0" />
          home
        </Button>
        <i> </i>
        <Button
          className={styles.button}
          tag={Link}
          to="/disconnect"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <i className="fas fa-fw fa-trash-alt mt-0" />
          ontkoppel optie
        </Button>
      </ModalHeader>

      <h2>Styling & Visual</h2>
      <h4>electronische labels</h4>
      <FormWithDisable
        submitting={isLoading}
        className="d-flex flex-column d-md-flex"
        onSubmit={() => {
          let b1 = barcode1;
          let b2 = barcode2;
          if (
            (tempBarcode.length === 13 && tempBarcode.startsWith("211")) ||
            tempBarcode.length < 7
          ) {
            b2 = tempBarcode;
            setOptionBarcode2(tempBarcode);
          } else if (tempBarcode.length >= 7) {
            b1 = tempBarcode;
            setESLBarcode1(tempBarcode);
          }
          setTempBarcode("");

          if (!b1 || !b2) {
            return;
          }

          mutate(
            {
              data: {
                eslBarcode: b1,
                optionLabel: b2,
              },
            },
            {
              onError: (e: any) => {
                const errorMessage = getErrorMessageFromResponse(
                  e.response,
                  "barcode scannen mislukt"
                );
                setErrorMessage(errorMessage);
              },
              onSuccess: () => {
                setESLBarcode1("");
                setOptionBarcode2("");
              },
            }
          );
        }}
      >
        <br />
        <h5 className={"ms-5 d-flex mt-2"}>
          <i className="fas fa-fw fa-barcode mt-0" />
          scan barcodes
        </h5>
        <input
          className="bg-light border text-light"
          type="text"
          name="dummy"
          autoFocus={true}
          value={tempBarcode}
          onChange={(e: any) => {
            setTempBarcode(e.target.value);
          }}
          style={{ opacity: 0.003 }} //a little bit visible during testing: finally it should not be visible.
          autoComplete="off"
        />
        <input
          className={"ms-5 me-auto"}
          value={barcode1}
          placeholder={"ESL barcode"}
          style={{ width: 208 }}
        />
        <br />
        <input
          className={"ms-5 me-auto"}
          value={barcode2}
          placeholder={"artikel barcode of label"}
          style={{ width: 208 }}
        />
        <Button className={styles.invisible} />
        <Button
          className="btn-light ms-5 me-auto text-white"
          onClick={(e: any) => {
            refreshPage();
          }}
        >
          refresh
        </Button>
        {isError && <Error message={`${errorMessage}`} />}
      </FormWithDisable>
    </div>
  );
}
