/**
 * Generated by orval v6.6.0 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */
import { useMutation, UseMutationOptions, MutationFunction } from "react-query";
import type { ScanDto } from "../model/ScanDto";
import queryApi from "../../../queryApi";
import type { OptionIdDto } from "../model/OptionIdDto";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

export const postImportScannerData = (scanDto: ScanDto) => {
  return queryApi<void>({
    url: `/link-labels`,
    method: "post",
    data: scanDto,
  });
};

export const usePostImportScannerData = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postImportScannerData>,
    TError,
    { data: ScanDto },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postImportScannerData>,
    { data: ScanDto }
  > = (props) => {
    const { data } = props || {};

    return postImportScannerData(data);
  };

  return useMutation<
    AsyncReturnType<typeof postImportScannerData>,
    TError,
    { data: ScanDto },
    TContext
  >(mutationFn, mutationOptions);
};

export const postDisconnectData = (optionIdDto: OptionIdDto) => {
  return queryApi<void>({
    url: `/remove-option`,
    method: "delete",
    data: optionIdDto,
  });
};

export const usePostDisconnectData = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDisconnectData>,
    TError,
    { data: OptionIdDto },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDisconnectData>,
    { data: OptionIdDto }
  > = (props) => {
    const { data } = props || {};

    return postDisconnectData(data);
  };

  return useMutation<
    AsyncReturnType<typeof postDisconnectData>,
    TError,
    { data: OptionIdDto },
    TContext
  >(mutationFn, mutationOptions);
};
