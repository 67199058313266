import { Label } from "reactstrap";
import styles from "./AbsoluteLabel.module.scss";
import * as CSS from "csstype";

function AbsoluteLabel({
  children,
  className,
  ...rest
}: {
  className?: string;
  children: any;
  for?: string;
  style?: CSS.Properties;
}) {
  return (
    <Label className={`${styles.absoluteLabel} ${className || ""}`} {...rest}>
      {children}
    </Label>
  );
}

export default AbsoluteLabel;
