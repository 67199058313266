const env = {
  API_URL: process.env.REACT_APP_API_URL || (window as any).ENV.API_URL,
  AUTH_API_URL:
    process.env.REACT_APP_AUTH_API_URL || (window as any).ENV.AUTH_API_URL,
  STATIC_ASSETS_ENDPOINT:
    process.env.REACT_APP_STATIC_ASSETS_ENDPOINT ||
    (window as any).ENV.STATIC_ASSETS_ENDPOINT,
};

export default env;
