import { newRidgeState } from "react-ridge-state";

export type UserType = {
  id: number;
  firstName: string;
  lastName: string;
  roles: { id: number; role: string }[];
  username: string;
};

export type TokensType = {
  accessToken: string;
  refreshToken: string;
};

const accessTokenKey = "accessToken";
const refreshTokenKey = "refreshToken";
const initialAccessToken = localStorage.getItem(accessTokenKey);
const initialRefreshToken = localStorage.getItem(refreshTokenKey);
const hasInitialData = initialAccessToken && initialRefreshToken;

export const authTokens = newRidgeState<TokensType | null>(
  hasInitialData
    ? { accessToken: initialAccessToken, refreshToken: initialRefreshToken }
    : null,
  {
    onSet: (newState) => {
      localStorage.setItem(accessTokenKey, newState?.accessToken || "");
      localStorage.setItem(refreshTokenKey, newState?.refreshToken || "");
    },
  }
);

export const userState = newRidgeState<UserType | null>(null);

export function useIsAuthenticated() {
  const tokens = authTokens.useValue();
  return !!tokens;
}

export function getAccessToken() {
  return authTokens.get()?.accessToken;
}
export function getRefreshToken() {
  return authTokens.get()?.refreshToken;
}

export function setTokens({ accessToken, refreshToken }: TokensType) {
  authTokens.set({ accessToken, refreshToken });
}
export function setUser(user: UserType) {
  userState.set(user);
}

export function logout() {
  authTokens.reset();
  userState.reset();
  window.location.href = "/login";
}
