import * as React from "react";
import { Spinner } from "reactstrap";

function CenteredSpinner() {
  return (
    <div className="pt-5 pb-5 d-flex justify-content-center">
      <Spinner size={"lg"} />
    </div>
  );
}

export default CenteredSpinner;
