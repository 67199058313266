import * as React from "react";
import { Button, Alert, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";

import FormWithDisable from "../global/FormWithDisable";
import { login } from "../api";
import InputWithLabel from "../global/InputWithLabel";
import styles from "./LoginPage.module.scss";

function LoginPage() {
  const navigate = useNavigate();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { mutate, isLoading, isError, error } = useMutation(async () => {
    await login({ username, password });
    navigate("/");
  });

  return (
    <div className="d-flex flex-fill align-items-center justify-content-center">
      <div className={styles.loginBox}>
        <FormWithDisable
          submitting={isLoading}
          className="login-form d-flex flex-column"
          onSubmit={() => mutate()}
        >
          <h4 className="login-head mb-4 d-flex">
            <i className="fas fa-fw fa-user mr-3" />
            inloggen
          </h4>
          <InputWithLabel
            className="mb-3"
            label="Gebruikersnaam"
            type="text"
            name="username"
            autoFocus="autofocus"
            placeholder="Gebruikersnaam"
            autoCapitalize="none"
            value={username}
            onChange={(e: React.ChangeEvent<any>) => {
              setUsername(e.target.value);
            }}
          />
          <InputWithLabel
            className="mb-3"
            label="Wachtwoord"
            type="password"
            name="password"
            placeholder="Wachtwoord"
            value={password}
            onChange={(e: React.ChangeEvent<any>) => {
              setPassword(e.target.value);
            }}
          />

          {isError && <Alert color="warning">Inloggen mislukt: {error}</Alert>}
          <div className="form-group btn-container">
            <Button color="primary" type="submit" block>
              {isLoading && <Spinner size="sm" className="me-2" />}
              Inloggen
            </Button>
          </div>
        </FormWithDisable>
      </div>
    </div>
  );
}

export default LoginPage;
