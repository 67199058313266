import * as React from "react";
import { Form } from "reactstrap";
import * as CSS from "csstype";

function FormWithDisable({
  onSubmit,
  submitting,
  style,
  ...rest
}: {
  children: any;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  submitting: boolean;
  style?: CSS.Properties;
  className?: string;
}) {
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        if (submitting) {
          return;
        }
        onSubmit(e);
      }}
      style={
        submitting
          ? {
              pointerEvents: "none",
              opacity: 0.8,
              cursor: "not-allowed",
              ...style,
            }
          : style
      }
      {...rest}
    />
  );
}

export default FormWithDisable;
